.footer {
    background-color: #f5f5f5;
    font-size: 14px;
    color: #777777;
    min-height: 180px;
}

.footer-nav {
    list-style: none;
    padding: 0px;
}

.footer-nav li {
    display: inline;
}

.footer-link, .footer-link:hover {
    color: #555555;
    text-decoration: underline;
}