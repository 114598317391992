.nav-logo{
    max-width: 180px;
    margin-left: 0px !important;
}

nav a {
    color: #777;
    text-decoration: none;
    padding-bottom: 6px;
    margin: 10px 0px 0px 20px;
}

nav a.active {
    color: #083E66;
    text-decoration: underline;
}

nav a:hover {
    color: #083E66;
    text-decoration: underline;
}