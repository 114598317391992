.vh-fill {
    min-height: calc(100vh - 180px);
}

.vh-70 {
    min-height: 70vh;
}

.header {
    background-image: url('/public/images/dahon-banner_480x480.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 48vh;
}

.dahon-btn-primary {
    background-color: #083E66;
    border: #083E66;
    border-radius: 4px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
}

.dahon-btn-primary:hover, .dahon-btn-primary:active, .ant-btn:hover, .ant-btn:focus, .ant-btn:active {
    background-color: #0C609F !important;
    border: #0C609F;
    color: #fff;
}

.dahon-btn-primary:disabled {
    background-color: #999999;
    border: #999999;
    color: #fff;
}

.dahon-btn-secondary {
    background-color: #ffffff;
    border: 1px solid #083E66;
    color: #083E66;
}

.dahon-btn-secondary:hover {
    background-color: #083E66;
    border: 1px solid #083E66;
}


.accordion-button:not(.collapsed) {
    background-color: #f5f5f5;
    color: #444;
    font-weight: 600;
}

.dahon-link {
    color: #083E66 !important;
}

.dahon-link:hover {
    color: #0C609F !important;
    text-decoration: underline;
}

.bg-dahon-blue {
    background-color: #083E66;
}

.ant-radio-button-wrapper {
    height: 100%;
    padding-bottom: 10px;    
}

.ant-radio-group {
    height: 100%;
}

.dahon-disclaimer {
    font-size: small;
}

.ant-input[disabled]{
    color: black !important;
  }



 .ant-form-item-required::before {
    content: "*";
    color: red;
    float: left;
  }

  .required-field::before{
    content: "*";
    color: red;
    float: left;
  }